body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'RobotoCondensed' ;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  /* overflow-y: auto; */
  height: 100%;
  background-color: #0E1C28;
  background: #0E1C28;
}

body {
    position: absolute;
    content: '';
    background-color: black;
    background: black;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center center !important;
    background-attachment: fixed !important;
    background-size: cover !important;
  }
  
  .app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @font-face {
    font-family: 'RobotoCondensed';
    src: url('fonts/RobotoCondensed-Regular.ttf') format('truetype');
    /* Add additional font formats if necessary */
    /* Specify the correct path to the font files */
  }
  /* @import "~react-image-gallery/styles/css/image-gallery.css"; */